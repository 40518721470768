import { InputNumber, InputNumberProps } from "antd"
import { VFC } from "react"
import styled from "styled-components"

interface Props extends InputNumberProps {
  suffix?: string
}

export const StyledNumberInput: VFC<Props> = ({ suffix, ...rest }) => {
  return (
    <StyledBox>
      <StyledSearchNumberWrapper>
        <InputNumber
          {...rest}
          controls={false}
          bordered={false}
        />
        <div className="suffix">{ suffix }</div>
      </StyledSearchNumberWrapper>
    </StyledBox>
  )
}

const StyledBox = styled.div`
  border: 1px solid #C4C4C4;
  border-radius: 1px;
  padding: 16px;
  height: 48px;
`
const StyledSearchNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 0;
  height: 16px;

  .ant-input-number {
    width: auto
  }

  .ant-input-number-input {
    padding: 0;
    width: 100%;
    height: 16px;

    /* EN/16/100/Medium */
    font-family: FormaleGrotesque;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1; /* 16px */
    font-feature-settings: 'clig' off, 'liga' off;
  }

  .suffix {
    /* EN/16/100/Medium */
    font-family: FormaleGrotesque;
    color: var(--ax-gray-3, #C4C4C4);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

`
