
import { useTranslation } from "next-i18next"
import { FC } from "react"

/**
 * 価格がお問い合わせが必要な時に表示されるラベル
 */

export const ArtWorkInquiryLabel: FC = () => {
  const { t } = useTranslation()

  return (
    <p style={{
      textAlign: "left",
      fontSize: 12, lineHeight: 1,
      textDecoration: "underline",
      height: "18px",
      wordWrap: "normal",
    }}>
      {t("価格はお問い合わせ")}
    </p>
  )
}
