import { Input, InputProps } from "antd"
import SearchIcon from "public/icons/search.svg"
import { Dispatch, useRef, VFC } from "react"
import styled from "styled-components"

interface Props extends InputProps {
  onSearch?: Dispatch<string>
}

export const StyledSearchInput: VFC<Props> = ({ onSearch, ...rest }) => {
  const textRef = useRef<string>("")
  return (
    <StyledSearchBox>
      <StyledInput
        {...rest}
        bordered={false}
        allowClear
        onChange={onSearch ? (e) => { textRef.current = e.target.value } : rest.onChange}
      />
      <SearchIcon
        onClick={() => { onSearch && onSearch(textRef.current) }}
        style={{ fontSize: "16px", height: "16px", width: "16px", cursor: "pointer" }}
      />
    </StyledSearchBox>
  )
}

const StyledSearchBox = styled.div`
  display: inline-flex;
  align-items: center;
  top: 0;
  border: 1px solid #C4C4C4;
  border-radius: 1px;
  gap: 14px;
  padding: 16px;
  width: 100%;
  height: 48px;

  .ant-input{
  /* JP/16/100/Medium */
    font-family: NotoSansJP, FormaleGrotesque;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    color: var(--ax-gray-5, #656565);
    letter-spacing: 0.8px;
  }
`

const StyledInput = styled(Input)`
    padding: 0;
`
