import { Typography } from "antd"
import { numberWithDelimiter } from "helpers/numberWithDelimiter"
import { useTranslation } from "next-i18next"
import { Dispatch, useEffect, useState, VFC } from "react"

import { StyledNumberInput } from "./StyledNumberInput"
import { StyledSlider } from "./StyledSlider"

type Props = {
  min?: number
  max?: number
  maxLimit: number
  setMin: Dispatch<number>
  setMax: Dispatch<number>
}

export const ArtWorkFilterFormItemPrice: VFC<Props> = ({
  maxLimit, min = 0, max = maxLimit, setMin, setMax
}) => {
  const { t } = useTranslation()
  const [inputValues, setInputValues] = useState<[number, number]>([min, max])

  useEffect(() => {
    setInputValues([min, max])
  }, [min, max])

  const handleChange = (value: [number, number]) => {
    setInputValues(value)
  }

  const handleAfterChange = (value: [number, number]) => {
    setMin(value[0])
    setMax(value[1])
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", paddingBottom: "28px" }}>
      <Typography.Text style={{ fontSize: "16px", lineHeight: 1, fontFeatureSettings: "'clig' off, 'liga' off", paddingBottom: "20px" }}>
        {t("価格")}
      </Typography.Text>

      <div style={{ display: "flex", flexDirection: "column", gap: "12px", paddingBottom: "24px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }} >
          <Typography.Text style={{ fontSize: "12px", lineHeight: 1, color: "#656565" }}>{t("最低価格")}</Typography.Text>
          <StyledNumberInput
            defaultValue={min}
            value={min}
            onChange={(v) => setMin(v as number)}
            style={{ display: "flex" }}
            suffix={"JPY"}
          />
        </div>
        <Typography.Text style={{ fontSize: "12px", lineHeight: 1, color: "#656565" }}>ー</Typography.Text>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }} >
          <Typography.Text style={{ fontSize: "12px", lineHeight: 1, color: "#656565" }}>{t("最高価格")}</Typography.Text>
          <StyledNumberInput
            defaultValue={max}
            value={max}
            onChange={(v) => setMax(v as number)}
            style={{ display: "flex" }}
            suffix={"JPY"}
          />
        </div>
      </div>
      <StyledSlider
        range
        min={0}
        max={maxLimit}
        step={1000}
        defaultValue={[min, max]}
        value={inputValues}
        onChange={handleChange}
        onAfterChange={handleAfterChange}
      />
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Typography.Text style={{ fontSize: "12px", lineHeight: 1, color: "#656565" }}>JPY {numberWithDelimiter(0)}</Typography.Text>
        <Typography.Text style={{ fontSize: "12px", lineHeight: 1, color: "#656565" }}>JPY {numberWithDelimiter(maxLimit)}</Typography.Text>
      </div>
    </div>
  )
}
