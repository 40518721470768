import api from "api"
import useSWR from "swr"

/**
 * Eventに表示するアーティストを取得するSWR
 */

export const useArtistsByEventId = (eventId?: string ) => {
  const path = eventId ? `/events/${eventId}/artists?limit=18` : null
  return useSWR<ArtistNameType[]>(path, (key) => api.get(key).then(res => res.data), {
    revalidateOnFocus: false
  })
}
