import { Checkbox } from "antd"
import styled from "styled-components"

export const StyledCheckBoxGroup = styled(Checkbox.Group)`
  .ant-checkbox-wrapper {
    padding: 4px 0;
    align-items: center;
    color: var(--ax-gray-5, #656565);
    font-feature-settings: 'clig' off, 'liga' off;

    /* EN/14/150/Medium */
    font-family: FormaleGrotesque;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
  .ant-checkbox {
    top: 0;
  }
  .ant-checkbox-inner {
    border-radius: 0;
    border: 1px solid var(--ax-gray-3, #CDCDCD);
    width: 20px;
    height: 20px;
  }
`
