import { Slider } from "antd"
import { SliderRangeProps } from "antd/lib/slider"
import { VFC } from "react"
import styled from "styled-components"

export const StyledSlider: VFC<SliderRangeProps> = ({ ...rest }) => {
  return (
    <StyledSliderWrapper {...rest} />
  )
}

const StyledSliderWrapper = styled(Slider)`
  margin: 11px;

  .ant-slider-track {
    height: 2px;
    border-radius: 40px;
    background-color: #232427;
  }

  .ant-slider-rail {
    height: 1px;
    border-radius: 40px;
    background-color: #C4C4C4;
  }

  .ant-slider-handle {
    width:20px;
    height: 20px;
    top: -0.5px;
    border: 1px solid var(--ax-gray-3, #C4C4C4);
    background-color: var(--AX--WHITE, #FFFFFF);
    box-shadow: 0 0 0 1px var(--ax-gray-3, #E0E0E0);
  }
`
