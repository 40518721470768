import api from "api"
import useSWR from "swr"

/**
 * 最高金額を取得するSWR
 */
export const useMaxPrice = () => {
  return useSWR<number>("/static/art_work_max_price", (key) => api.get(key).then(res => res.data), {
    revalidateOnFocus: false
  })
}
