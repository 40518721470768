import api from "api"
import useSWRInfinite, { SWRInfiniteResponse } from "swr/infinite"

type SearchParamsType = {
  artists_id_in?: string[]
  name_cont?: string
}

/**
 * アーティストを取得するSWR
 * @params {SearchParamsType} search_params 検索 option
 * @params {number} limit 取得数
 */

export const useArtists = (search_params: SearchParamsType, limit?:number):SWRInfiniteResponse<Artist[], any> => {
  const getKey = (pageIndex:number, previousPageData:Artist[]) => {
    if (previousPageData && !previousPageData.length) return null
    return { path: "/artists", search_params, page: pageIndex + 1, limit }
  }

  const fetcher = ({ path, search_params, page, limit }:{ path:string, search_params:SearchParamsType, page:number, limit?:number }) =>
    api.get<Artist[]>(path, {
      params: {
        search_params,
        page,
        limit,
      }
    }).then(res => res.data)

  return useSWRInfinite(
    getKey, fetcher, {
      initialSize: 1,
      revalidateOnFocus: false
    }
  )
}
